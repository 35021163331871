import { Button, CardActions, CardContent, Modal, Typography, CircularProgress, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { ConfirmCard } from '../Layout/styles';
import { useEffect, useState } from 'react';
import { useConductors } from '../../hooks/useConductor';

interface FordaModalProps {
    open: boolean;
    onClose: () => void;
    isSelectedForda: boolean;
    saveFordaLoading: boolean;
    initFordaName: string;
    initConductor: string;
    onSave: (fordaName: string, conductor: string) => void;
}

export default function FordaModal({ open, onClose, isSelectedForda, saveFordaLoading, initFordaName, initConductor, onSave }: FordaModalProps) {
    const { conductors } = useConductors();
    const [fordaName, setFordaName] = useState<string>(initFordaName);
    const [selectedConductor, setSelectedConductor] = useState<string>(initConductor);

    useEffect(() => {
        setFordaName(initFordaName);
    }, [initFordaName]);

    useEffect(() => {
        setSelectedConductor(initConductor);
    }, [initConductor]);

    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent sx={{ p: 2 }}>
                    <Typography variant={'h6'} pb={3}>
                        {isSelectedForda ? 'Forda szerkesztése' : 'Forda létrehozása'}
                    </Typography>
                    <TextField
                        label='Forda név'
                        fullWidth
                        value={fordaName}
                        onChange={e => setFordaName(e.target.value)}
                        sx={{ mb: 2 }}
                        variant='outlined'
                    />
                    <FormControl fullWidth>
                        <InputLabel id='conductor-select-label'>Vezénylő</InputLabel>
                        <Select
                            labelId='conductor-select-label'
                            id='conductor-select'
                            value={selectedConductor || ''}
                            label='Vezénylő'
                            onChange={e => setSelectedConductor(e.target.value as string)}
                        >
                            {conductors.map(conductor => (
                                <MenuItem key={conductor} value={conductor}>{conductor}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        size={'small'}
                        color={'info'}
                        onClick={onClose}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        Mégsem
                    </Button>
                    <Button
                        variant='contained'
                        size={'small'}
                        sx={{ borderRadius: 50, marginRight: 0.25 }}
                        onClick={() => onSave(fordaName, selectedConductor)}
                        endIcon={saveFordaLoading ? <CircularProgress size={20} color='inherit' /> : <SaveAsIcon />}
                        disabled={saveFordaLoading}>
                        {saveFordaLoading ? 'Mentés...' : isSelectedForda ? 'Mentés' : 'Létrehozás'}
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}
