import { createContext, useContext, useEffect, useState } from "react"
import { useAuth } from "./useAuth"
import { Props } from "../@types"


type ConductorContextType = {
    conductors: string[]
}

const ConductorContext = createContext<ConductorContextType>({
    conductors: []
})

const useProvideConductors = () => {
    const { user } = useAuth()
    const [conductors, setConductors] = useState<string[]>([])

    useEffect(() => {
        (async () => {
            const storedConductors = await localStorage.getItem("conductors");
            if (!storedConductors) {
                return;
            }
            const parsedConductors: string[] = JSON.parse(storedConductors);
            await setConductors(parsedConductors);
        })();
    }, [])

    useEffect(() => {
        const fetchConductorList = async() => {
            const storedConductors = await localStorage.getItem("conductors");
            if (!user?.accessToken || (storedConductors && storedConductors.length > 0)) {
                return;
            }
            const response = await fetch("/api/v1/conductors", {
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`
                }
            })
            type ConductorResponse = {
                conductors: string[]
            }
            const data: ConductorResponse = await response.json()
            setConductors(data.conductors)
        }
        fetchConductorList()
    }, [user])

    useEffect(() => {
        if (conductors.length > 0) {
            localStorage.setItem("conductors", JSON.stringify(conductors));
        }
    }, [conductors])

    return { conductors }
}

const useConductors = () => {
    return useContext(ConductorContext)
}

const ProvideConductors = ({ children }: Props) => {
    const conductors = useProvideConductors()
    return <ConductorContext.Provider value={conductors}>{children}</ConductorContext.Provider>
}

export { useConductors, ProvideConductors }